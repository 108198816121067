import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  id: "app"
};
import { useUserStore } from '@/stores/user';
export default {
  __name: 'App',
  setup(__props) {
    const userStore = useUserStore();
    console.log(userStore.token);
    if (userStore.token) {
      // window.__ssc.setting = { hideIcon: false };
      let user = userStore.userinfo;
      ssq.push('setLoginInfo', {
        user_id: user.id,
        // 加密后的用户id, 必填！
        user_name: user.name,
        // 对应用户名
        email: user.email,
        // 对应用户邮箱
        phone: user.phone
      });
    } else {
      // window.__ssc.setting = { hideIcon: true };
    }
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_router_view)]);
    };
  }
};